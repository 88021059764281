<template>
  <div>
    <div id="header" style="padding-bottom: 50px !important">
      <div class="container d-flex flex-column align-items-center">
        <h1>Witaj Administratorze!</h1>
        <h3>Cieszymy się, że jesteś z Nami!</h3>
        <SearchBar />
      </div>
      <AdminPageContainers />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import SearchBar from "../components/SearchBar";
import AdminPageContainers from "../components/AdminPageContainers";

export default {
  name: "Admin",
  components: { AdminPageContainers, SearchBar },
  data: () => {
    return {
      projects: [],
    };
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
    config() {
      return this.$store.getters.config;
    },
  },
};
</script>
