var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ul',{staticClass:"m-0 p-0 d-flex flex-column"},_vm._l((_vm.posts),function(post){return _c('li',{key:post.ID,class:{
      'list-open': post.open,
    }},[_c('div',{staticClass:"post-tree-name cursor-pointer",class:{
        'font-weight-bold': post.open,
      }},[_c('div',{staticClass:"tree-title",class:{
          'has-child': post.childs && post.childs.length,
        },on:{"click":function($event){return _vm.openPost(post)}}},[_vm._v(" "+_vm._s(post.post_title)+" ")])]),(post.childs && post.childs.length && post.open)?_c('CourseBookTree',{staticClass:"ml-5",attrs:{"posts":post.childs},on:{"post":_vm.updatePost}}):_vm._e()],1)}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }