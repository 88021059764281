<template>
  <div>
    <div id="courseBookHeader">
      <div class="container">
        <h1 class="text-center">Tutoriale</h1>
        <h3 class="text-center">
          Filmy instuktażowe oraz tip&tricks ułatwiające <br />korzystanie z
          systemu
        </h3>
      </div>
      <div class="container homepage-containers">
        <div class="row">
          <a
            v-for="post in posts"
            :href="post.link"
            :key="post.id"
            target="_blank"
            class="col-12 col-md-6 col-lg-4 mb-4"
          >
            <div
              class="knowledge-base-container user tutorial d-flex flex-column align-items-center"
            >
              <div
                class="main-photo"
                :style="`background-image: url(${post.better_featured_image.source_url})`"
              ></div>
              <p
                class="text-blue text-left full-width py-3 px-3 font-weight-bold"
              >
                {{ post.title.rendered }}
              </p>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
// @ is an alias to /src
export default {
  name: "KnowledgeBaseTutorials",
  data() {
    return {
      posts: [],
    };
  },
  mounted() {
    this.$http
      .get(
        `/wp/v2/posts?per_page=100&_embed&trand=iHxmKppCluZD9v872ce4KoSrpwDlsmoc5VaWNmjJ`
      )
      .then((response) => {
        this.posts = _.filter(response.data, (post) => {
          post.link = this.recreateUrl(post.link);
          if (post.categories.includes(1748)) return post;
        });
      });
  },
  methods: {
    recreateUrl(link) {
      const cutOff = "https://old-support.hrsys.pl/";
      const cutOffSecond = "https:/old-support.hrsys.pl/";
      if (link.includes(cutOff) || link.includes(cutOffSecond)) {
        link = link.replace(cutOff, "").replace(cutOffSecond, "");
      }
      return `/topic/${link}`;
    },
  },
};
</script>
