<template>
  <div id="header" class="pb-0">
    <div class="container">
      <h1>Aktualności</h1>
      <h3>Najnowsze informacje o zmianach w systemie</h3>
      <SearchBar />
    </div>
    <div class="container" style="margin-top: 250px">
      <div class="row">
        <div class="col-12 col-lg-9">
          <div class="container">
            <div class="row" v-if="news.length">
              <transition-group
                name="fade"
                tag="div"
                class="col-12 col-md-6 col-xl-4"
                :key="topic.id"
                v-for="topic in news"
              >
                <TopicContainer :key="topic.id" :topic="topic" />
              </transition-group>
            </div>
            <div class="row" v-else-if="loading">
              <div class="col-12 justify-content-center d-flex">
                <Loader />
              </div>
            </div>
            <div class="row" v-else-if="!loading && !news.length">
              <div class="col-12 justify-content-center d-flex">
                <p>Brak aktualności w tej kategorii.</p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-3">
          <CategoriesBox />
          <LastNewsBox />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import SearchBar from "../components/SearchBar";
import CategoriesBox from "../components/CategoriesBox";
import LastNewsBox from "../components/LastNewsBox";
import TopicContainer from "../components/TopicContainer";
import forEach from "lodash.foreach";
import Loader from "../components/Loader";
export default {
  components: { Loader, TopicContainer, LastNewsBox, CategoriesBox, SearchBar },
  data() {
    return {
      news: [],
      loading: true,
    };
  },
  mounted() {
    this.getNews();
  },
  watch: {
    categoryId() {
      this.loading = true;
      this.news = [];
      window.scrollTo(0, 700);
      this.getNews();
    },
  },
  computed: {
    categoryId() {
      return this.$route.params.categoryId;
    },
  },
  methods: {
    getNews() {
      this.$http
        .get(
          `wp/v2/posts?categories=${this.categoryId}&per_page=30&_embed&trand=iHxmKppCluZD9v872ce4KoSrpwDlsmoc5VaWNmjJ`
        )
        .then((response) => {
          this.loading = false;
          forEach(response.data, (news, index) => {
            setTimeout(() => {
              news.show = false;
              this.news.push(news);
              setTimeout(() => {
                this.news[index].show = true;
              });
            }, index * 100);
          });
        });
    },
  },
};
</script>
