<template>
  <div class="main-container">
    <div id="documentationHeader" style="padding-bottom: 0; margin-bottom: 0">
      <div class="container">
        <h1 class="text-center">Dokumentacja</h1>
        <h3 class="text-center">
          Pobieraj instrukcje, dokumenty, <br />materiały jakie przygotowaliśmy
          dla Ciebie.
        </h3>
      </div>
    </div>
    <transition type="slide-down" v-if="tags && tags.length">
      <div class="container" style="padding-top: 60px">
        <div
          v-masonry="`tagsContainer`"
          transition-duration="0.3s"
          class="row mt-5"
          item-selector=".tag-parent"
        >
          <div
            class="col-12 col-md-6 col-lg-4 mt-3 tag-parent"
            v-masonry-tile
            v-for="tag in tags"
            :key="tag.term_id"
          >
            <div class="tag-container d-flex flex-column">
              <h6 class="tag-name">{{ tag.name }}</h6>

              <ul class="d-flex flex-column">
                <li
                  class="full-width mb-3"
                  :key="post.ID"
                  v-for="post in tag.posts"
                >
                  <router-link
                    class="post-link"
                    :to="`/documentation/post/${post.ID}`"
                  >
                    {{ post.post_title }}
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </transition>
    <div class="container d-flex justify-content-center my-5" v-else>
      <Loader />
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { VueMasonryPlugin } from "vue-masonry";

Vue.use(VueMasonryPlugin);
import Loader from "../components/Loader";
export default {
  name: "Documentation",
  components: { Loader },
  data() {
    return {
      tags: [],
    };
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
  },
  mounted() {
    this.$http
      .get("wp/v2/manualtags?trand=iHxmKppCluZD9v872ce4KoSrpwDlsmoc5VaWNmjJ")
      .then((response) => {
        this.tags = response.data;
      });
  },
};
</script>

<style scoped lang="scss">
@import "src/assets/scss/colors";

.tag-container {
  background: #ffffff;
  box-shadow: 0px 8px 64px rgba(82, 120, 185, 0.4);
  border-radius: 16px;
  padding: 0px 20px;
  display: flex;
  .tag-name {
    color: $purple;
    font-size: 20px;
    font-weight: 600;
    margin-top: 15px;
    margin-bottom: 15px;
    display: flex;
  }
  .post-link {
    font-size: 18px;
    font-weight: 400;
    color: $dark;
    line-height: 27px;
  }
}
</style>
