<template>
  <div class="main-container">
    <div id="courseBookHeader" style="padding-bottom: 0px; margin-bottom: 0">
      <div class="container">
        <h1 class="text-center">
          Podręcznik dla
          {{
            $route.params.type === "admin" ? "administratora" : "użytkownika"
          }}
        </h1>
        <h3 class="text-center">
          Opis wszystkich funkcjonalności dostępnych dla
          {{
            $route.params.type === "admin" ? "administratorów" : "użytkowników"
          }}
          <br />
          korzystających z systemu w trybie standardowym.
        </h3>
        <div class="row">
          <div class="col-12 col-lg-2"></div>
          <div class="col-12 col-lg-8 d-flex justify-content-center">
            <div
              class="d-flex justify-content-center align-items-center mb-5 full-width flex-column"
            >
              <SearchBar />
            </div>
          </div>
          <div class="col-12 col-lg-2"></div>
        </div>
      </div>
    </div>
    <div class="container" v-if="!loading" style="padding-top: 150px">
      <div class="row">
        <div class="col-12 col-lg-3 mb-3 no-print mb-5">
          <CourseBookTree @post="updateCurrentPost" :posts="posts" />
        </div>
        <div class="col-12 col-lg-9 col-print-12 body-content">
          <div
            v-if="currentPost"
            class="topic-container overflow-visible p-0"
            style="
              height: auto;
              box-shadow: 0px 8px 64px rgba(82, 120, 185, 0.4);
            "
          >
            <div
              class="d-flex p-5 search-post pb-5 mb-5 full-width align-items-start"
            >
              <div
                class="icon-container d-flex align-items-start justify-content-start mr-4"
              >
                <i class="far fa-file-alt"></i>
              </div>
              <div
                class="post-details full-width d-flex flex-wrap flex-column position-relative"
              >
                <div class="print-button" @click="print">
                  <i class="fas fa-print"></i>
                </div>
                <h5 class="post-title">
                  {{ currentPost.post_title }}
                </h5>
                <div class="d-flex flex-column flex-lg-row flex-wrap">
                  <div
                    class="d-flex text-purple align-items-center mr-lg-4 my-1"
                  >
                    <i class="far fa-calendar-alt mr-1"></i>
                    {{ currentPost.post_date | formatDate }}
                  </div>
                  <div
                    class="d-flex text-purple align-items-center ml-lg-3 my-1"
                  >
                    <i class="fas fa-user mr-1"></i>
                    {{ currentPost.author }}
                  </div>

                  <EditPostLink :id="currentPost.ID" />
                </div>
              </div>
            </div>
            <div class="full-width px-5 pb-5 post-content">
              <div v-html="currentPost.post_content"></div>

              <div class="mt-5">
                <LikeButton :url="`${location}/${currentPost.ID}`" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      style="padding-top: 150px"
      class="container d-flex justify-content-center align-items-center"
      v-else
    >
      <Loader />
    </div>
  </div>
</template>

<script>
import SearchBar from "../components/SearchBar";
import CourseBookTree from "../components/CourseBookTree";
import Loader from "../components/Loader";
import LikeButton from "../components/LikeButton";
import EditPostLink from "../components/EditPostLink";

export default {
  name: "Coursebook",
  components: { EditPostLink, LikeButton, Loader, CourseBookTree, SearchBar },
  data() {
    return {
      posts: [],
      currentPost: null,
      loading: true,
      location: window.location.href,
    };
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
  },
  mounted() {
    this.$http
      .get(
        `wp/v2/getCourseBook?id=${
          this.$route.params.type === "user" ? "35" : "46"
        }&trand=iHxmKppCluZD9v872ce4KoSrpwDlsmoc5VaWNmjJ`
      )
      .then((response) => {
        this.posts = response.data;
        this.posts[0].views = 0;
        this.currentPost = this.posts[0];
        // this.$http
        //   .get(
        //     `aalaap/v1/countview/${this.currentPost.ID}?_embed&trand=iHxmKppCluZD9v872ce4KoSrpwDlsmoc5VaWNmjJ`,
        //     { id: this.id }
        //   )
        //   .then((response) => {
        //     this.currentPost.views = response.data;
        //     this.loading = false;
        //   });

        this.loading = false;
      });
  },
  methods: {
    print() {
      window.print();
    },
    updateCurrentPost(data) {
      this.loading = true;
      this.$nextTick(() => {
        this.currentPost = data;
        this.loading = false;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.search-post {
  border-bottom: 2px dashed #179c02;
}
</style>
