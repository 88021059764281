<template>
  <div>
    <div id="courseBookHeader">
      <div class="container">
        <h1 class="text-center">Przewodnik</h1>
        <h3 class="text-center">
          Twój osobisty przewodnik <br />po wszystkich etapach współpracy z nami
        </h3>
      </div>
      <div class="container homepage-containers">
        <div class="row">
          <a
            href="/guide/Wdrożenie.pdf"
            download
            class="col-12 col-md-6 col-lg-4"
          >
            <div
              href="/knowledge-base/guide"
              class="knowledge-base-container user d-flex flex-column align-items-center text-center"
            >
              <div class="download-icon"></div>
              <img src="/img/Wdrożenie.png" alt="" />
            </div>
            <h3 class="text-dark">Wdrożenie</h3>
          </a>
          <a
            href="/guide/Utrzymanie.pdf"
            download
            class="col-12 col-md-6 col-lg-4"
          >
            <div
              href="/knowledge-base/guide"
              class="knowledge-base-container user d-flex flex-column align-items-center text-center"
            >
              <div class="download-icon"></div>
              <img src="/img/Utrzymanie.png" alt="" />
            </div>
            <h3 class="text-dark">Utrzymanie</h3>
          </a>
          <a
            href="/guide/Customer Experience.pdf"
            download
            class="col-12 col-md-6 col-lg-4"
          >
            <div
              href="/knowledge-base/guide"
              class="knowledge-base-container user d-flex flex-column align-items-center text-center"
            >
              <div class="download-icon"></div>
              <img src="/img/Customer Experience.png" alt="" />
            </div>
            <h3 class="text-dark">Customer Experience</h3>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
export default {
  name: "KnowledgeBaseGuide",
};
</script>
