<template>
  <div class="post-comments" v-if="user">
    <div class="d-flex flex-column full-width" v-if="!loading">
      <h5 class="font-weight-bold mb-4">
        {{ postComments.length }}
        {{
          postComments.length === 1
            ? "komentarz"
            : postComments.length === 0
            ? "komentarzy"
            : "komentarze"
        }}
      </h5>

      <div class="full-width">
        <div
          class="col-12 px-0 mb-4 d-flex"
          v-for="comment in postComments"
          :key="comment.id"
        >
          <div
            class="user-avatar"
            :style="`background-image: url(${comment.author_avatar_urls['48']})`"
          ></div>
          <div class="comment-content">
            <div class="d-flex justify-content-between flex-column flex-lg-row">
              <div class="full-width">
                <h6 class="font-weight-bold">{{ comment.author_name }}</h6>
                <div v-html="comment.content.rendered"></div>
              </div>
              <div class="post-date">{{ comment.date | formatDate }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-center my-4" v-else>
      <Loader />
    </div>

    <form
      @submit.prevent="postComment"
      class="add-comment mt-5 d-flex full-width flex-column"
    >
      <h5 class="font-weight-bold mb-4">Dodaj komentarz</h5>
      <textarea
        name="comment"
        v-model="comment"
        id="comment"
        cols="15"
        rows="4"
        required
        class="form-control full-width"
        placeholder="Komentarz..."
      ></textarea>
      <div class="d-flex mt-4 justify-content-center">
        <button class="post-comment" type="submit">Dodaj komentarz</button>
      </div>
    </form>
  </div>
  <div class="post-comments row full-width" v-else>
    <div class="col-12">
      <router-link :to="`/login`" class="text-purple"
        >Komentarze są dostępne dla osób zalogowanych, kliknij aby się
        zalogować.
      </router-link>
    </div>
  </div>
</template>

<script>
import Loader from "./Loader";
export default {
  components: { Loader },
  props: {
    post: Object,
  },
  data() {
    return {
      comment: "",
      postComments: [],
      loading: true,
    };
  },
  name: "PostComments",
  watch: {
    user() {
      this.getComments();
    },
  },
  computed: {
    user() {
      return this.$store.getters.userData;
    },
    config() {
      return this.$store.getters.config;
    },
    slug() {
      return this.$route.params.slug;
    },
  },
  mounted() {
    this.getComments();
  },
  methods: {
    getComments() {
      this.$http
        .get(
          `wp/v2/comments?post=${this.post.id}&trand=iHxmKppCluZD9v872ce4KoSrpwDlsmoc5VaWNmjJ`,
          this.config
        )
        .then((response) => {
          this.postComments = response.data || [];
        })
        .then(() => {
          this.loading = false;
        });
    },
    postComment() {
      this.loading = true;

      this.$http
        .post(
          `/wp/v2/comments?trand=iHxmKppCluZD9v872ce4KoSrpwDlsmoc5VaWNmjJ`,
          {
            post: this.post.id,
            author: this.user.id,
            content: this.comment,
          },
          this.config
        )
        .then(() => {
          this.comment = "";
          this.getComments();
        });
    },
  },
};
</script>

<style scoped lang="scss">
.post-date {
  font-size: 14px;
  font-weight: 400;
  color: #1fdc02;
  min-width: 150px;
  text-align: right;
}
.user-avatar {
  width: 40px;
  height: 40px;
  min-width: 40px;
  border-radius: 100%;
  background-size: cover !important;
  background-position: center !important;
  margin-right: 22px;
}
.comment-content {
  background: #f9f9f9;
  border-radius: 10px;
  width: 100%;
  padding: 24px;
}
.post-comments {
  margin-top: 40px;
  padding-top: 40px;
  border-top: 2px dashed #179c02;
}

.post-comment {
  background: #003c78;
  border-radius: 8px;
  padding: 8px 16px;
  font-size: 14px;
  font-weight: 600;
  color: white;
  border: 0;
}
</style>
