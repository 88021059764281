<template>
  <div>
    <div id="courseBookHeader">
      <div class="container">
        <h1 class="text-center">Webinary</h1>
        <h3 class="text-center">
          Rozwijaj swoją wiedzę i kompetencje dzięki naszym <br />cyklicznym
          webinarom HR!
        </h3>
      </div>
      <div class="container homepage-containers">
        <div class="row">
          <a
            href="https://youtu.be/Wa6O-bSTMqA"
            target="_blank"
            class="col-12 col-md-6 col-lg-4 mb-4"
          >
            <div
              class="knowledge-base-container user tutorial d-flex flex-column align-items-center"
            >
              <div
                class="main-photo"
                style="background-image: url('/img/youtube-video15.jpg')"
              ></div>
              <p
                class="text-blue text-left full-width py-3 px-3 font-weight-bold"
              >
                Wyzwania HR
              </p>
            </div>
          </a>
          <a
            href="https://youtu.be/yH-r4G88ZSw"
            target="_blank"
            class="col-12 col-md-6 col-lg-4 mb-4"
          >
            <div
              class="knowledge-base-container user tutorial d-flex flex-column align-items-center"
            >
              <div
                class="main-photo"
                style="background-image: url('/img/youtube-video14.jpg')"
              ></div>
              <p
                class="text-blue text-left full-width py-3 px-3 font-weight-bold"
              >
                Pre- i onboarding pracownika
              </p>
            </div>
          </a>
          <a
            href="https://www.youtube.com/watch?v=4xdU63Uh7Mk&feature=youtu.be&ab_channel=Hsys"
            target="_blank"
            class="col-12 col-md-6 col-lg-4 mb-4"
          >
            <div
              class="knowledge-base-container user tutorial d-flex flex-column align-items-center"
            >
              <div
                class="main-photo"
                style="background-image: url('/img/youtube-video13.jpg')"
              ></div>
              <p
                class="text-blue text-left full-width py-3 px-3 font-weight-bold"
              >
                Executive search
              </p>
            </div>
          </a>
          <a
            href="https://www.youtube.com/watch?v=E13al8XxrD0&ab_channel=Hsys"
            target="_blank"
            class="col-12 col-md-6 col-lg-4 mb-4"
          >
            <div
              class="knowledge-base-container user tutorial d-flex flex-column align-items-center"
            >
              <div
                class="main-photo"
                style="background-image: url('/img/youtube-video12.jpg')"
              ></div>
              <p
                class="text-blue text-left full-width py-3 px-3 font-weight-bold"
              >
                Luka rekrutacyjna webinar HR
              </p>
            </div>
          </a>
          <a
            href="https://www.youtube.com/watch?v=dKF7yrLGDnw&ab_channel=Hsys"
            target="_blank"
            class="col-12 col-md-6 col-lg-4 mb-4"
          >
            <div
              class="knowledge-base-container user tutorial d-flex flex-column align-items-center"
            >
              <div
                class="main-photo"
                style="background-image: url('/img/youtube-video11.jpg')"
              ></div>
              <p
                class="text-blue text-left full-width py-3 px-3 font-weight-bold"
              >
                Feedback w organizacji webinar HR
              </p>
            </div>
          </a>
          <a
            href="https://www.youtube.com/watch?v=LO0GerDi42Q&ab_channel=Hsys"
            target="_blank"
            class="col-12 col-md-6 col-lg-4 mb-4"
          >
            <div
              class="knowledge-base-container user tutorial d-flex flex-column align-items-center"
            >
              <div
                class="main-photo"
                style="background-image: url('/img/youtube-video7.jpg')"
              ></div>
              <p
                class="text-blue text-left full-width py-3 px-3 font-weight-bold"
              >
                Kontroling HR nagranie webinaru
              </p>
            </div>
          </a>
          <a
            href="https://www.youtube.com/watch?v=NdEzjoQCUT0&ab_channel=Hsys"
            target="_blank"
            class="col-12 col-md-6 col-lg-4 mb-4"
          >
            <div
              href="/knowledge-base/guide"
              class="knowledge-base-container user tutorial d-flex flex-column align-items-center"
            >
              <div
                class="main-photo"
                style="background-image: url('/img/youtube-video3.jpg')"
              ></div>
              <p
                class="text-blue text-left full-width py-3 px-3 font-weight-bold"
              >
                Jak zarządzać szkoleniami w #HR
              </p>
            </div>
          </a>
          <a
            href="https://www.youtube.com/watch?v=QS2avuyKIeo&ab_channel=Hsys"
            target="_blank"
            class="col-12 col-md-6 col-lg-4 mb-4"
          >
            <div
              href="/knowledge-base/guide"
              class="knowledge-base-container user tutorial d-flex flex-column align-items-center"
            >
              <div
                class="main-photo"
                style="background-image: url('/img/youtube-video4.jpg')"
              ></div>
              <p
                class="text-blue text-left full-width py-3 px-3 font-weight-bold"
              >
                Baza Wiedzy webinar
              </p>
            </div>
          </a>
          <a
            href="https://www.youtube.com/watch?v=V0L1x1syqFs&ab_channel=Hsys"
            target="_blank"
            class="col-12 col-md-6 col-lg-4 mb-4"
          >
            <div
              href="/knowledge-base/guide"
              class="knowledge-base-container user tutorial d-flex flex-column align-items-center"
            >
              <div
                class="main-photo"
                style="background-image: url('/img/youtube-video5.jpg')"
              ></div>
              <p
                class="text-blue text-left full-width py-3 px-3 font-weight-bold"
              >
                Webinar Ocena okresowa
              </p>
            </div>
          </a>
          <a
            href="https://www.youtube.com/watch?v=CUriAo4I7JQ&ab_channel=Hsys"
            target="_blank"
            class="col-12 col-md-6 col-lg-4 mb-4"
          >
            <div
              href="/knowledge-base/guide"
              class="knowledge-base-container user tutorial d-flex flex-column align-items-center"
            >
              <div
                class="main-photo"
                style="background-image: url('/img/youtube-video6.jpg')"
              ></div>
              <p
                class="text-blue text-left full-width py-3 px-3 font-weight-bold"
              >
                Webinar z modułu ZADANIA
              </p>
            </div>
          </a>
          <a
            href="https://www.youtube.com/watch?v=-pUDtvGEBU8&ab_channel=Hsys"
            target="_blank"
            class="col-12 col-md-6 col-lg-4 mb-4"
          >
            <div
              href="/knowledge-base/guide"
              class="knowledge-base-container user tutorial d-flex flex-column align-items-center"
            >
              <div
                class="main-photo"
                style="background-image: url('/img/youtube-video10.jpg')"
              ></div>
              <p
                class="text-blue text-left full-width py-3 px-3 font-weight-bold"
              >
                Komunikacja wewnętrza webinar HR
              </p>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
export default {
  name: "KnowledgeBaseWebinars",
};
</script>
