<template>
  <div class="login-page d-flex justify-content-center align-items-center">
    <div class="login-container p-5">
      <h3 class="font-weight-bold text-center">Zaloguj się aby kontynuować</h3>
      <form @submit.prevent="submit" class="row mt-5">
        <div class="col-12">
          <label for="username">Login lub adres e-mail</label>
          <input
            type="text"
            v-model="username"
            id="username"
            required
            name="username"
            class="form-control"
          />
        </div>
        <div class="col-12 mt-4">
          <label for="password">Hasło</label>
          <input
            v-model="password"
            type="password"
            id="password"
            required
            name="password"
            class="form-control"
          />
        </div>
        <div class="col-12 mt-4 d-flex justify-content-between">
          <div>
            <div class="custom-checkbox-hsys">
              <input
                type="checkbox"
                name="rememberme"
                id="rememberme"
                v-model="rememberme"
              />
              <label for="rememberme">Zapamiętaj mnie</label>
            </div>
          </div>
        </div>
        <div
          class="col-12 mt-4 d-flex justify-content-center align-items-center"
        >
          <button type="submit">Zaloguj się</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  name: "Login",
  data() {
    return {
      username: "",
      password: "",
      rememberme: false,
      prevRoute: null,
    };
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
    config() {
      return this.$store.getters.config;
    },
  },
  mounted() {
    if (this.user) {
      this.$router.push("/");
    }
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.prevRoute = from;
    });
  },
  methods: {
    submit() {
      this.$http
        .post(
          `jwt-auth/v1/token?trand=iHxmKppCluZD9v872ce4KoSrpwDlsmoc5VaWNmjJ`,
          {
            username: this.username,
            password: this.password,
            rememberme: this.rememberme,
          }
        )
        .then((response) => {
          this.$store.commit("updateUser", response.data);
          this.$router.push(
            this.prevRoute && this.prevRoute.path ? this.prevRoute.path : "/"
          );
        })
        .catch(() => {
          this.$swal("", "Wprowadzone dane logowania są niepoprawne.", "error");
        });
    },
  },
};
</script>

<style scoped lang="scss">
.login-container {
  height: 585px;
  width: 785px;
  max-width: 90%;
  box-shadow: 0px 8px 64px rgba(82, 120, 185, 0.4);
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  background: white;
  z-index: 100;

  .form-control {
    border: 1px solid #179c02;
    border-radius: 16px;
  }

  button[type="submit"] {
    background: #1fdc02;
    border-radius: 16px;
    padding: 16px 30px;
    font-size: 24px;
    font-weight: 600;
    color: white;
    border: 0;
  }
}
</style>
